// referenced from react-otp-input
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

type AllowedInputTypes = 'password' | 'text' | 'number' | 'tel';
interface OTPInputProps {
  className?: string;
  errorText?: string;
  inputType?: AllowedInputTypes;
  label?: string;
  length?: number;
  onChange: (otp: string) => void;
  onCompleteInput: () => void;
  placeholder?: string;
  value?: string;
}

const OTPInput = ({
  className,
  errorText,
  inputType = 'number',
  // label,
  length = 4,
  onChange,
  onCompleteInput,
  placeholder,
  value = '',
}: OTPInputProps) => {
  const { t } = useTranslation();

  const [activeInput, setActiveInput] = React.useState(0);
  const inputRefs = React.useRef<Array<HTMLInputElement | null>>([]);

  const getOTPValue = () => (value ? value.toString().split('') : []);

  const isInputNum = inputType === 'number' || inputType === 'tel';

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, length);
  }, [length]);

  const getPlaceholderValue = () => {
    if (typeof placeholder === 'string') {
      if (placeholder.length === length) {
        return placeholder;
      }

      if (placeholder.length > 0) {
        // console.error('Length of the placeholder should be equal to the number of inputs.');
      }
    }
    return undefined;
  };

  const isInputValueValid = (value: string) => {
    const isTypeValid = value == '0' || (isInputNum ? !!Number(value) : typeof value === 'string');
    return isTypeValid && value.trim().length === 1;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (isInputValueValid(value)) {
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { nativeEvent } = event;
    const { value } = event.target;

    if (!isInputValueValid(value)) {
      // Pasting from the native autofill suggestion on a mobile device can pass
      // the pasted string as one long input to one of the cells. This ensures
      // that we handle the full input and not just the first character.
      if (value.length === length) {
        const hasInvalidInput = value.split('').some((cellInput) => !isInputValueValid(cellInput));
        if (!hasInvalidInput) {
          handleOTPChange(value.split(''));
          focusInput(length - 1);
        }
      }

      // @ts-expect-error - This was added previously to handle and edge case
      // for dealing with keyCode "229 Unidentified" on Android. Check if this is
      // still needed.
      if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
        event.preventDefault();
        changeCodeAtFocus('');
        focusInput(activeInput - 1);
      }

      // Clear the input if it's not valid value because firefox allows
      // pasting non-numeric characters in a number type input
      event.target.value = '';
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => (index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const otp = getOTPValue();
    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    }
    // React does not trigger onChange when the same value is entered
    // again. So we need to focus the next input manually in this case.
    else if (event.key === otp[activeInput]) {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(length - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOTPValue();
    // eslint-disable-next-line prefer-destructuring
    otp[activeInput] = value[0];
    handleOTPChange(otp);
  };

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join('');
    onChange(otpValue);
    if (otpValue.length === length) {
      onCompleteInput();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const otp = getOTPValue();
    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, length - activeInput)
      .split('');

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (isInputNum && pastedData.some((value) => isNaN(Number(value)))) {
      return;
    }

    // Paste data from focused input onwards
    for (let pos = 0; pos < length; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? '';
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    handleOTPChange(otp);
    onCompleteInput();
  };

  const getTranslatedErrorText = () => {
    if (errorText === 'Required') {
      return t('forms.required');
    }
    return errorText;
  };

  return (
    <div className={clsx('mb-8 mt-4 flex w-full flex-col gap-4', className)}>
      <div
        className={clsx('flex w-full justify-around gap-4')}
        onPaste={handlePaste}
        style={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {Array.from({ length }, (_, index) => index).map((index) => (
          <React.Fragment key={index}>
            <input
              aria-controls="off"
              aria-label={`Please enter OTP character ${index + 1}`}
              autoComplete="off"
              className="input-number-no-controls aspect-square max-h-14 min-w-0 max-w-14 flex-1 appearance-none rounded-16 border-[#3232470D] text-center text-32 shadow outline-none"
              inputMode={isInputNum ? 'numeric' : 'text'}
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={(event) => handleFocus(event)(index)}
              onInput={handleInputChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              placeholder={getPlaceholderValue()?.[index] ?? ''}
              ref={(element) => {
                inputRefs.current[index] = element;
              }}
              type={inputType}
              value={getOTPValue()[index] ?? ''}
            />
          </React.Fragment>
        ))}
      </div>

      {errorText && (
        <span className="text-center text-16 font-6 text-red-500">{getTranslatedErrorText()}</span>
      )}
    </div>
  );
};

export default OTPInput;
